/** Global **/
html,
body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  -ms-font-smoothing: antialiased !important;
  min-height: 100%;
}

/** Body **/
body {
  margin: 0;
  padding: 0;

  background-color: $bgBody;

  .container {
    background-color: transparent;
  }

  .raised {
    &.lv1 {
      box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.2);
    }

    &.lv2 {
      box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  //Form fileds
  .custom-file-control,
  .form-control,
  .is-focused .custom-file-control,
  .is-focused .form-control {
    box-shadow: none !important;
    background-image: linear-gradient(
        0deg,
        $mainClr 2px,
        rgba(242, 153, 74, 0) 0
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.26) 1px, transparent 0) !important;
  }

  .form-group.line-light {
    background-image: linear-gradient(
        0deg,
        #acacac 2px,
        rgba(242, 153, 74, 0) 0
      ),
      linear-gradient(0deg, rgba(0, 0, 0, 0.26) 1px, transparent 0) !important;
  }

  label {
    color: $textGreyLight !important;
  }

  select.form-control:not([size]):not([multiple]) {
    height: calc(2.4375rem);
  }

  input {
    &.bg-transparent {
      background-color: transparent;
    }
  }

  //Datapicker
  .dtp {
    div.dtp-date,
    table.dtp-picker-days tr > td > a.selected {
      background-color: #f2994a;
    }

    > .dtp-content > .dtp-date-view > header.dtp-header {
      background-color: #c27a3b;
    }

    .p10 > a {
      color: #ffffff;
    }
  }

  //Toogle IOs
  .form-switch {
    display: inline-block;
    cursor: pointer;

    i {
      position: relative;
      display: inline-block;
      margin-right: 0.5rem;
      width: 36px;
      height: 16px;
      border-radius: 23px;
      vertical-align: text-bottom;
      transition: all 0.3s linear;
      background-color: $grayLight;

      &i:before {
        content: '';
        position: absolute;
        left: 0;
        width: 32px;
        height: 12px;
        background-color: $white;
        border-radius: 11px;
        transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
        transition: all 0.25s linear;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        width: 12px;
        height: 12px;
        background-color: $white;
        border-radius: 11px;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
        transform: translate3d(2px, 2px, 0);
        transition: all 0.2s ease-in-out;
      }
    }

    &:active i::after {
      width: 28px;
      transform: translate3d(2px, 2px, 0);
    }

    &:active input:checked + i::after {
      transform: translate3d(16px, 2px, 0);
    }

    input {
      display: none;

      &:checked + i {
        background-color: $mainClr;
      }

      &:checked + i::before {
        transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
      }

      &:checked + i::after {
        transform: translate3d(22px, 2px, 0);
      }
    }
  }

  // Buttons
  .btn {
    &.btn-default {
      background-color: $mainClr;
      color: $white;

      &:hover {
        background-color: $mainClr;
      }

      &.disabled:hover {
        background-color: $gray;
        cursor: default;
      }
    }

    &.btn-orange {
      background-color: $mainClr;
      color: $white;
    }

    &.slim {
      padding: 2px 10px;
    }

    &.btn-disabled {
      color: $white;
      background-color: $mainClrDisabled;
    }
    &.btn-disabled:hover {
      background-color: $mainClrDisabled;
    }
  }

  .msg-error {
    position: absolute;
    display: none;

    &.on-error {
      display: block;
      color: $danger;
      font-size: 0.7rem;
    }

    .nav-link.active {
      height: 100%;
      background-color: red;
    }
  }

  //Dark box
  .greydark {
    background-color: $grayDark;
    color: $textLight;

    .border-bottom,
    &.border-bottom {
      border-bottom: solid 1px $textGreyLight !important;
    }

    .border-top,
    &.border-top {
      border-top: solid 1px $textGreyLight !important;
    }

    .nav-link {
      color: $textGreyDark;

      &.active {
        color: $mainClr;
      }
    }

    .nav-tabs {
      border-bottom: solid 2px $textGreyLight;
    }

    .nav-tabs .nav-link {
      &.active,
      &:hover {
        background-color: transparent;
      }
    }
    .about p {
      text-align: justify;
    }
  }

  //Vertical align
  .v-align {
    display: flex;
    flex-wrap: wrap;
    height: 100%;

    .top {
      align-self: flex-start;
    }

    .bottom {
      align-self: flex-end;
    }
  }

  //Plus - minus buttons
  .num-increase {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
      padding: 0 10px;
      margin: 0;
    }

    i {
      color: $mainClr;
      border-radius: 45px;
      cursor: pointer;
      font-size: 24px;

      &:hover {
        box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.2);
      }
    }
  }
}

/** CONTENT **/
//Tab nav
.nav-tabs .nav-link {
  border-bottom: solid 1px transparent;
  height: 100%;

  &.active,
  &:hover {
    border-bottom: solid 4px $mainClr;
    background-color: $light;
  }
}

//Checkin checkout dates
.datapicker .card-body,
.about .card-body {
  padding-top: 0;
  padding-bottom: 0;
}

.datapicker {
  i {
    font-size: 18px;
  }

  .card-body {
    .row {
      align-items: baseline;
    }

    .fa-calendar-alt {
      color: $textGreyLight;
    }
  }
}

//About & contact
.about .card-body {
  span.nav-link {
    cursor: pointer;
    font-family: $titleFnt;
    font-weight: 800;
    text-transform: capitalize;
    padding: 10px;

    &.active:hover {
      border-bottom: solid 2px $mainClr;
    }
  }

  .map-container {
    width: 100%;
    height: 300px;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
    }
  }
}

//Pictures
.pictures {
  a {
    color: $textDark !important;
  }

  .img {
    display: flex;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    flex-direction: column;
    justify-content: flex-end;

    .description {
      width: fit-content;
      line-height: 1em;
      border-top-right-radius: 5px;
      background: rgba(255, 255, 255, 0.6);
    }
  }
}

.roomPlans section {
  padding-bottom: 10px;
  border-bottom: 1px #eee solid;
}

.roomPlans section:last-child {
  border-bottom: none;
}

//Room infos
.room-info {
  .infos {
    display: flex;
    flex-direction: column;
  }

  .small {
    color: $mainClr;
  }

  p.details {
    font-size: 10px;
    margin: 0;
    color: $textDefault;

    i {
      font-size: 10px;
      color: $textDefault;
    }
  }

  .amenity {
    padding-bottom: 10px;

    i {
      font-size: 15px;
    }

    .list-inline-item {
      margin-right: 0;

      span {
        display: inline-block;
        margin-right: 5px;

        &:before {
          font-size: 20px;
          color: #67718e;
          margin-right: 11px;
          display: inline-block;
          margin-top: 15px;
        }
      }
    }
  }

  span {
    margin-right: 15px;

    &.promo {
      font-size: 0.7rem;
      color: $textGreyLight;
      text-decoration: line-through;
    }

    &.value {
      font-size: 1.1rem;
    }
  }

  .options {
    display: flex;
  }

  .observations {
    color: #dc143c;
    font-size: 1rem;
  }
}

//Guests infos
.guests {
  form {
    .form-row {
      .form-group {
        width: 48%;

        input {
          width: 100%;
        }
      }
    }
  }
}

// Modal
.modal {
  .modal-content {
    .modal-header {
      // padding: 0px 6px 0;
      border-bottom: 0;
    }

    .modal-body {
      padding-top: 0;
    }

    h3 {
      font-size: 1.5rem;

      span {
        display: block;
        font-size: 0.9rem;
        color: $grayLight;
        margin: 0.7rem 0 1.5rem 0;
      }
    }

    .amenity {
      li {
        display: block;

        i {
          width: 20px;
          text-align: center;
        }
      }
    }

    .owl-slider {
      .owl-theme .owl-dots {
        text-align: left;

        .owl-dot span {
          width: 20px;
          height: 20px;
          border-radius: 0;
        }
      }
    }
  }
}

//Currency modal
.modal-currency {
  h2 {
    width: 100%;
  }

  .list-currency {
    display: flex;
    flex-wrap: wrap;

    li {
      display: flex;
      width: 100%;
      align-items: center;
      box-sizing: border-box;
      padding: 0.3rem;
      cursor: pointer;

      &:hover,
      &.current {
        background-color: $grayLight;
        color: $white;

        * {
          color: $white !important;
        }
      }

      span {
        &.code {
          display: block;
          width: 40px;
          font-size: 13px;
        }

        &.link {
          display: block;
          width: 100%;
          box-sizing: border-box;
          color: $mainClr;
          font-size: 13px;
        }

        &:hover {
          color: $white !important;
        }
      }
    }
  }
}

.as-link {
  cursor: pointer;
}

.tooltip {
  .arrow:before {
    border-top-color: rgba(97, 97, 97, 0.9);
  }
}

.button-drop-shadow {
  font-size: 23px;
  cursor: pointer;
  -webkit-filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.2));
  filter: drop-shadow(1px 2px 1px rgba(0, 0, 0, 0.2));

  &:hover {
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
  }
}

.btn-c-cfm {
  margin: 0 auto;
  display: block;
  font-size: 16px;
}

.checkout-summary {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 0;
}

.checkout-summary.highlighted {
  background-color: #fff0f0 !important;
}

.is-fixed {
  position: fixed;
  top: 0;
  margin-top: 1rem;
  width: 255px;
}

.is-fixed-ended {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.room-thumbnail {
  width: 100%;
  height: auto;
}

.mapbox-icon {
  width: 50px;
  height: auto;
}

.product-image {
  width: auto;
  height: 252px;
  background: url(../../images/default-product.png) no-repeat center center;

  &.fixed {
    background: no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

.text-truncate {
  line-height: 1.1rem;
  overflow: visible;
}

.prod-card-title {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.8rem;
}

.track-day {
  .card-body {
    min-height: 132px;
  }
}

.payment-tabs {
  width: 100%;
  border-color: #f2994a;

  &:hover,
  &:active,
  .active {
    border-color: #f2994a;
  }
}

.mapboxgl-ctrl-zoom-in {
  background: url('../../images/plus-solid.png') no-repeat 6px 6px;
  background-size: 18px 18px;
}

.mapboxgl-ctrl-zoom-out {
  background: url('../../images/minus-solid.png') no-repeat 6px 6px;
  background-size: 18px 18px;
}

//Custom content

.card.mb-3 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0), 0 3px 1px -2px rgba(0, 0, 0, 0),
    0 1px 5px 0 rgba(0, 0, 0, 0);
}

.card.mt-n4.mb-4,
.card.mt-md-n4.mb-3.about {
  background: transparent;
  box-shadow: none;
}

.card-body.availability-wrapper,
.about .card-body.greydark,
.pictures {
  padding: 50px 20px 40px 20px;
  border-radius: 25px;
  background-color: hsla(0, 0%, 100%, 0.75);
  -webkit-backdrop-filter: blur(2px);
  // backdrop-filter: blur(2px);
  box-shadow: 2px 1px 8px 3px rgba(0, 0, 0, 0.11);
  margin-top: -40px;

  p {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 25px;
    padding: 0px 8px;
  }
}
.card-body {
  padding: 50px 20px 40px 20px;
  border-radius: 25px;
  background-color: hsla(0, 0%, 100%, 0.75);
  -webkit-backdrop-filter: blur(2px);
  // backdrop-filter: blur(2px);
  box-shadow: 2px 1px 8px 3px rgba(0, 0, 0, 0.11);
}
.availability-wrapper {
  position: relative;
  z-index: 2;

  .row {
    justify-content: center;
  }
}

.about .card-body {
  color: $textGreyDark;
}
.row.availability {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .btn {
    background: $mainClr;
    color: $white;
    font-family: $titleFnt;
    font-weight: 700;
    font-size: 13px;
    letter-spacing: 2px;
    padding: 13px 18px;
    border-radius: 100px;
  }
  .btn:hover {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
  .col-lg-7 .btn {
    border: solid 3px;
    border-radius: 100px;
    background: $white;
    color: $mainClr;
    font-family: $titleFnt;
    font-weight: 700;
    text-transform: none;
    padding: 5px;
    padding-right: 35px;
    box-shadow: none;
    font-size: 15px;
    letter-spacing: 0px;
  }
  .col-lg-7 .btn::after {
    content: '\f0d7';
    font-family: $FAregular;
    font-weight: 900;
    position: absolute;
    right: 14px;
    top: calc(50% - 11px);
    font-size: 17px;
  }
  .mb-3 {
    margin-bottom: 0px !important;
  }
}

.pictures {
  margin-top: 0px;
  padding: 0px;

  .img {
    border: solid 10px $mainClr;
    border-bottom: solid 25px $mainClr;
    position: relative;
  }
  .img::after {
    font-family: $FAregular;
    font-weight: 900;
    content: '\f302';
    position: absolute;
    right: 0px;
    color: $white;
    background: $mainClr;
    padding: 15px;
    border-radius: 15px 0px 0px 0px;
    font-size: 19px;
  }
}

.card.mb-4 {
  box-shadow: none;
  background: transparent;
}

ul.nav.nav-tabs.justify-content-center.w-100 {
  margin: auto;
}

footer {
  overflow: hidden;
}

.modal.show .modal-dialog {
  height: 93vh;
}

.modal-content {
  padding: 25px;
}

.DateRangePicker {
  border: solid 2px $mainClr;
  border-left: solid 35px $mainClr;
  border-radius: 7px 4px 4px 7px;
}

.DateRangePicker::after {
  font-family: $FAregular;
  font-weight: 400;
  content: '\f073';
  position: absolute;
  left: -24px;
  color: $white;
  font-size: 14px;
  top: 15px;
}

ul.language-list {
  background: $white;
  padding: 10px;
  border-radius: 10px;
  color: $textGreyDark;

  span {
    cursor: pointer;
  }
}

.room-info {
  h3 {
    font-size: 22px;
    line-height: 25px;
    font-weight: 800;
    text-transform: none;
  }
}

.track-day {
  .card {
    box-shadow: 2px 1px 8px 3px rgba(0, 0, 0, 0.11);
  }
  .card-body {
    box-shadow: unset;
  }
}

.form-row {
  .col-12.col-md-6 {
    padding: 0px 15px;
  }
}

.rccs__card--unknown > div {
  background: $mainClr -webkit-linear-gradient(top right, hsla(0, 0%, 100%, 0), rgba(0, 0, 0, 0.61));
}

.buttonColor {
  fill: $mainClr;
  border-radius: 100%;
  svg > path {
    fill: $mainClr;
  }
}

.CalendarDay__hovered_span:active,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #fff;
  border: 1px double $mainClr2;
  color: $mainClr;
}

.CalendarDay__selected_span {
  background: #f5f5f5;
  border: 1px double #f5f5f5;
  color: $mainClr;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $mainClr;
  border: 1px double $mainClr2;
  color: #fff;
}

.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: $mainClr;
  border: 1px double $mainClr2;
  color: #fff;
}

.nav-tabs .nav-link.active {
  border-color: $mainClr2 !important;
}

.btn.btn-primary,
.btn-primary.custom-file-control::before {
  background: $mainClr;
  border: 1px double $mainClr2;
  color: #fff;
}

.btn.btn-primary:hover,
.btn-primary.custom-file-control:hover::before {
  background: $mainClr2;
  border: 1px double $mainClr;
  color: #fff;
}

.my-whatsapp-fab{
  position:fixed;
  width:60px;
  height:60px;
  bottom:40px;
  right:40px;
  background-color:#25d366;
  border-radius:50px;
  text-align:center;
  font-size:30px;
  box-shadow: 2px 2px 3px #999;
  z-index:100;
}
.my-whatsapp-fab-float{
  margin-top:16px;
  color: #fff;
}

@media (min-width: 576px) {
  .modal-sm {
    max-width: 450px;
  }
}

@media (max-width: 1200px) {
  .availability-wrapper .row {
    justify-content: center;
  }
  .row.availability {
    flex-direction: column;
    margin: 0px;

    .col-12.col-lg-7.text-center.mb-3.mb-md-5.mb-lg-0 {
      width: 100%;
      max-width: inherit;
      margin: 0px;
    }
    .btn {
      padding: 10px 35px 10px 10px;
      width: 100%;
    }
  }

  .col-lg-7 .btn {
    margin-bottom: 15px;
  }
}

@media (max-width: 992px) {
  .container.pb-4 .row {
    flex-direction: column;

    .col-12.col-md-9,
    .col-12.col-md-3.mb-3 {
      max-width: 100%;
    }
    .about .card-body.greydark {
      margin-top: 25px;
    }
    .steps {
      .header .logo-header span.logo img {
        bottom: 24px;
      }
      .md-stepper-horizontal {
        width: 100%;
        max-width: 100%;
      }
    }
    .availability-wrapper .row {
      flex-direction: row;
    }
  }
  .sidebar {
    display: flex;
    flex-direction: column-reverse;
  }
  .checkout-summary .col-7,
  .checkout-summary .col-5 {
    width: 100%;
    max-width: 100%;
  }
}

@media (min-width: 1600px){
  .container {
    max-width: 1400px;
    width: 100%;
  }
}

// fixme
@media (min-width: 800px){
  .card-body-top {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-bottom: 0px;
  }
  .card-body-middle {
    margin-top: -20px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top: 0px;
    border-bottom: 0px;
  }
  .card-body-base {
    margin-top: -20px;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-top: 0px;
  }
}
